









































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import LayoutDefault from '@/layouts/LayoutDefault.vue'
import ProductService from '@/services/ProductService'
import { namespace } from 'vuex-class'
const CartProductStore = namespace('CartProductStore')
const CartProductStoreEdit = namespace('CartProductStoreEdit')
const ModeBuyProduct = namespace('ModeBuyProduct')

@Component({
  components: {
    LayoutDefault
  }
})
export default class ProductList extends Vue {
  public isCheckFullQuantity: boolean = false
  public isLoggedIn: boolean = false
  public isLoadingProductDetail: boolean = false
  public imgProductPreview: any = []
  public breadcrumbCategory: any = []
  private isFavourite: boolean = false
  private productDetailPreview: any = ''
  private productDetailPreviewId: any = ''
  private productPreviewQuantity: any = 1
  private stockQuantity: any = 0

  @CartProductStore.Action
  public updateFromCart!: (data: any) => any
  @CartProductStore.Action
  private addMultiToCart!: (data: any) => any
  @CartProductStoreEdit.Action
  private addMultiToCartEdit!: (data: any) => any
  @CartProductStore.Getter
  private getCart!: any
  @CartProductStoreEdit.Getter
  private getCartEdit!: any
  @ModeBuyProduct.Getter
  private getModeBuyProduct!: number

  @Watch('$route.params.id', { deep: true, immediate: true })
  watchFetchDataProduct() {
    this.fetchDataProductDetail()
  }

  created() {
    if (localStorage.getItem('user')) {
      this.isLoggedIn = true
    }
    this.fetchDataProductDetail()
  }

  public fetchDataProductDetail() {
    this.productPreviewQuantity = 1
    this.isLoadingProductDetail = true
    this.productDetailPreviewId = Number(this.$route.query.id)
    if (localStorage.getItem('store_uuid')) {
      ProductService.getProductDetail(this.productDetailPreviewId).then(
        (response: any) => {
          if (response.data.items && response.data.items.length > 0) {
            this.productDetailPreview = response.data.items[0]
            this.breadcrumbCategory = this.productDetailPreview.display_category
            this.imgProductPreview = this.productDetailPreview.images
            this.handleCheckFullQuantity(this.productDetailPreview)
            this.isFavourite = this.productDetailPreview.is_favourite
            document.title =
              (this.productDetailPreview.product_name_display == null
                ? this.productDetailPreview.product_name
                : this.productDetailPreview.product_name_display) +
              ' | 明治屋ネットストアー'
          }
          this.isLoadingProductDetail = false
        },
        (error: any) => {
          if (error.response.status === 429) {
            localStorage.clear()
            this.$bvModal.show(`too-many-request`)
          }
          this.productDetailPreview =
            (error.response && error.response.data) ||
            error.message ||
            error.toString()
        }
      )
    }
  }

  public toggleProductToFavorite(productDetailPreview: any) {
    if (this.isFavourite) {
      ProductService.removeProductFavorite(Number(productDetailPreview.id))
    } else {
      ProductService.addProductFavorite(Number(productDetailPreview.id))
    }
    this.isFavourite = !this.isFavourite
  }

  public handleCheckFullQuantity(productDetailPreview: any) {
    const item = this.checkItem().find(
      (e: any) => e.id === productDetailPreview.id
    )
    if (item && item.quantity === item.stock_quantity) {
      this.isCheckFullQuantity = true
    }
  }

  public async addProductPreviewToCart(productDetailPreview: any) {
    let tour_mode =
      localStorage.getItem('tour_mode') &&
      localStorage.getItem('tour_mode') === 'on'
        ? true
        : false
    if (tour_mode || this.isLoggedIn === false) {
      this.$bvModal.show('modal-add-to-cart-redirect-tour-mode')
    } else {
      let isPassRegex: any = /^-?\d*$/.test(
        this.productPreviewQuantity.toString()
      )
      if (!isPassRegex) {
        this.$toast(`数量項目には半角数字しか入力できません。`, {
          timeout: 5000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: 'button',
          icon: false,
          rtl: false
        })
      } else {
        if (this.productPreviewQuantity < 1) {
          this.$toast(`商品数量は1以上で入力ください`, {
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: 'button',
            icon: false,
            rtl: false
          })
        } else {
          await ProductService.getProductQuantity(productDetailPreview.id).then(
            (response: any) => {
              this.stockQuantity = response.data.items
            },
            (error: any) => {
              if (error.response.status === 429) {
                localStorage.clear()
                this.$bvModal.show(`too-many-request`)
              }
            }
          )

          let productToAdd = {
            id: productDetailPreview.id,
            name: productDetailPreview.product_name,
            product_name_display: productDetailPreview.product_name_display,
            manufacture_name: productDetailPreview.manufacture_name,
            img_url:
              productDetailPreview.images.length > 0
                ? productDetailPreview.images[0].path
                : null,
            stock_quantity: this.stockQuantity,
            quantity: Number(this.productPreviewQuantity),
            normal_price_excluding_tax:
              productDetailPreview.normal_price_excluding_tax,
            normal_price_including_tax:
              productDetailPreview.normal_price_including_tax,
            note: productDetailPreview.note,
            mode: this.getModeBuyProduct,
            skip: 0,
            tax_classification: productDetailPreview.tax_classification,
            temperature_control_name:
              productDetailPreview.temperature_control.name,
            temperature_control_price:
              productDetailPreview.temperature_control.price,
            temperature_control_id: productDetailPreview.temperature_control.id
          }
          if (this.checkQuantityProduct() === true) {
            this.checkAddOrder(productToAdd)
          }
        }
      }
    }
  }

  public checkQuantityProduct() {
    let isCorrectQuantity = true
    let modeCurrent = this.getModeBuyProduct
    if (this.productPreviewQuantity == 0) {
      this.$toast(`商品数量は1以上で入力ください`, {
        timeout: 5000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: 'button',
        icon: false,
        rtl: false
      })
      this.productPreviewQuantity = 1
      isCorrectQuantity = false
      return isCorrectQuantity
    }
    if (this.productPreviewQuantity > 100) {
      this.$toast(`一度にご注文できる最大数は100個までとなります。`, {
        timeout: 5000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: 'button',
        icon: false,
        rtl: false
      })
      isCorrectQuantity = false
      return isCorrectQuantity
    }
    this.checkItem().forEach((item: any) => {
      if (item.id == this.productDetailPreviewId) {
        if (item.mode === modeCurrent && item.quantity >= this.stockQuantity) {
          if (this.stockQuantity === 0) {
            this.$toast(`この商品は在庫がありません。`, {
              timeout: 5000,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.6,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: 'button',
              icon: false,
              rtl: false
            })
          } else {
            this.$toast(
              `この商品は` + this.stockQuantity + `個のみが残ります。`,
              {
                timeout: 5000,
                closeOnClick: true,
                pauseOnFocusLoss: true,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: 'button',
                icon: false,
                rtl: false
              }
            )
          }
          isCorrectQuantity = false
        }
        if (item.quantity + Number(this.productPreviewQuantity) > 100) {
          this.$toast(`一度にご注文できる最大数は100個までとなります。`, {
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: 'button',
            icon: false,
            rtl: false
          })
          isCorrectQuantity = false
        }
      } else {
        if (Number(this.productPreviewQuantity) > 100) {
          this.$toast(`一度にご注文できる最大数は100個までとなります。`, {
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: 'button',
            icon: false,
            rtl: false
          })
          isCorrectQuantity = false
        }
      }
    })
    return isCorrectQuantity
  }

  private checkAddOrder(productToAdd: any) {
    if (localStorage.getItem('order_id_edit')) {
      this.addMultiToCartEdit(productToAdd)
      this.$bvModal.show('modal-notice-add-to-cart-success')
    } else {
      this.addMultiToCart(productToAdd)
      this.$bvModal.show('modal-notice-add-to-cart-success')
    }
  }

  private checkItem() {
    let items = []
    if (localStorage.getItem('order_id_edit')) {
      items = this.getCartEdit
    } else {
      items = this.getCart
    }
    return items
  }

  private saveCurrentPaginationProduct() {
    localStorage.setItem(
      'lastCurrentPaginationProduct',
      String(this.$route.query.lastCurrentPaginationProduct)
    )
  }
}
